<template>
    <el-main>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="用户姓名：">
                <el-input size="small" v-model="searchForm.name" placeholder="请输入用户姓名"></el-input>
            </el-form-item>
            <el-form-item label="用户手机：">
                <el-input size="small" type="number" v-model="searchForm.u_mobile" placeholder="请输入用户手机"></el-input>
            </el-form-item>
            <el-form-item label="订单号：">
                <el-input size="small" v-model="searchForm.order_sn" placeholder="请输入订单号"></el-input>
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="name" label="用户姓名" width="150" align="center"></el-table-column>
            <el-table-column prop="u_mobile" label="用户手机" align="center" width="150"></el-table-column>
            <el-table-column prop="type" label="积分来源" align="center">
                <template v-slot="{ row }">
                    {{ row.type == -1 ? '兑换优惠券' : row.type == -2 ? '积分清零' : row.type == 1 ? '注册成功送积分' : row.type == 2 ?
                        '完善个人资料送积分' : row.type == 3 ? '手机验证送积分' : row.type == 4 ? '邮箱验证送积分' : row.type == 5 ? '每日登录送积分' :
                            row.type == 6 ? '开通/充值VIP会员送积分' : row.type == 7 ? '邀请用户并首次消费送积分' : row.type == 8 ? '商品评论送积分' : row.type
                                == 9 ? '购物送积分' : row.type == 10 ? '服务送积分' : row.type == 11 ? '到店付款送积分' : row.type == 12 ? '后台赠送' :
                                    row.type == 13 ? '活动赠送' : row.type == 14 ? '活动额外奖励' : '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="income_type" label="收入类型" align="center" width="100">
                <template v-slot="{ row }">
                    {{ row.income_type == -1 ? '支出' : '收入' }}
                </template>
            </el-table-column>
            <el-table-column prop="integral" label="积分" width="120" align="center">
                <template v-slot="{ row }">
                    {{ row.income_type == -1 ? '-' : '+' }}{{ row.integral }}
                </template>
            </el-table-column>
            <el-table-column prop="order_sn" label="订单号" align="center">
                <template v-slot="{ row }">
                    {{ row.order_sn ? row.order_sn : '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="120" align="center">
                <template v-slot="{ row }">
                    {{ row.status == -1 ? '冻结中' : '正常' }}
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" width="200" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import _ from 'lodash';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                name: '',
                u_mobile: '',
                order_sn: '',
                start_time: '',
                end_time: '',
            },
        };
    },
    created () {
        this.getList();
    },
    methods: {
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                name: '',
                u_mobile: '',
                order_sn: '',
                start_time: '',
                end_time: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.repair.IntegralSet.log, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .sortList {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
}

.el-main {
    background: #fff;
    display: flex;
    flex-direction: column;
}
</style>
